<template>
  <div class="plans-dropdown" :class="`position-${position}`">
    <div
      class="plan-container w-100 d-flex justify-content-between align-items-center"
    >
      <p class="m-0 text-20 font-weight-bold">{{ currentPlan.name }}</p>
      <b-button
        v-if="plans.length > 1"
        pill
        variant="primary"
        size="md"
        class="change-btn d-flex align-items-center"
        @click="$store.commit('env/setIsSwitchCourseSheetShow', true)"
      >
        <i class="icon-Arrow-left-right text-20 mr-1"></i>
        {{ $t('切換課程') }}
      </b-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    position: {
      type: String,
      default: 'sticky',
    },
  },
  computed: {
    plans() {
      return this.$store.getters['course/plans'] || [];
    },
    currentPlan() {
      return this.$store.getters['course/currentPlan'];
    },
  },
};
</script>

<style lang="scss" scoped>
.plans-dropdown {
  line-height: 54px;
  top: -1px;
  z-index: 4;
  /deep/ .btn-link {
    padding: 0;
    text-decoration: none;
  }
}
.plan-container {
  max-width: 100%;
  margin: 0 auto;
  padding: 0 32px;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    height: 1px;
    background: #dcdfe6;
    width: calc(100% - 64px);
  }
}

@media screen and (min-width: 128px) {
  .plan-container {
    max-width: 1172px;
  }
}
</style>

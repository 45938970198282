<template>
  <div class="mobile-learning-container w-100 p-3">
    <div class="course-status bg-white w-100 pt-2 pb-3">
      <div class="process-text px-3">
        {{ currentPlan.name }}{{ $t('學習進度') }}
      </div>
      <hr class="mt-2" />
      <div
        v-if="processCourses.length !== 0"
        class="process-content px-3"
        :style="processStyle"
      >
        <div
          v-for="(course, index) in processCourses"
          :key="`course${index}`"
          class="lesson-day w-100 h-100 d-flex flex-column align-items-center justify-content-center text-white position-relative"
          :class="{
            'lesson-finish': course.totalFinish === course.totalCourses,
          }"
          @click="go2lesson(course)"
        >
          <span :style="lessonTextStyle">{{ getDayText(course.day) }}</span>
          <i v-if="course.isLocked" class="icon-Lock" :style="lockStyle"></i>
          <div
            v-else
            class="finish-record d-flex align-items-center justify-content-center w-100 position-absolute"
            :style="recordStyle"
          >
            <i
              v-for="i in course.totalCourses"
              :key="i"
              class="icon-Star"
              :style="starStyle"
              :class="{bright: course.totalFinish >= i}"
            ></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import plansMixin from '@/mixin/plans';

export default {
  mixins: [plansMixin],
  props: {
    processCourses: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isPageLoading: true,
      isDataLoading: false,
    };
  },
  computed: {
    userData() {
      return this.$store.getters['user/userData'];
    },
    currentPlan() {
      return this.$store.getters['course/currentPlan'];
    },
    currentCourses() {
      return this.$store.getters['course/currentCourses'];
    },
    processStyle() {
      let rows = 0;
      if (this.processCourses.length === 5) {
        rows = 5;
      } else if (this.isPlan30k_10k) {
        rows = 7;
      } else if (this.isPlanKid2week) {
        rows = 4;
      } else {
        rows = 4;
      }
      return {
        gridTemplateColumns: `repeat(${rows}, 1fr)`,
        gridAutoRows: `calc((100vw - 16px * 4 - 4 * ${rows - 1}px) / ${rows})`,
      };
    },
    lessonTextStyle() {
      if (this.currentPlan.courseCount === 5) {
        return {
          fontSize: '4.375vw',
        };
      } else if (this.isPlan30k_10k) {
        return {
          fontSize: '3.733vw',
        };
      } else if (this.isPlanKid2week) {
        return {
          fontSize: '6.672vw',
        };
      } else {
        return {
          fontSize: '6.672vw',
        };
      }
    },
    lockStyle() {
      if (this.currentPlan.courseCount === 5) {
        return {
          fontSize: '4.375vw',
        };
      } else if (this.isPlan30k_10k) {
        return {
          fontSize: '3.733vw',
        };
      } else if (this.isPlanKid2week) {
        return {
          fontSize: '6.672vw',
        };
      } else {
        return {
          fontSize: '6.672vw',
        };
      }
    },
    recordStyle() {
      if (this.currentPlan.courseCount === 5) {
        return {
          height: '4.5vw',
        };
      } else if (this.isPlan30k_10k) {
        return {
          height: '3.2533vw',
        };
      } else if (this.isPlanKid2week) {
        return {
          height: '5.7188vw',
        };
      } else {
        return {
          height: '5.7188vw',
        };
      }
    },
    starStyle() {
      if (this.currentPlan.courseCount === 5) {
        return {
          fontSize: '4.5vw',
        };
      } else if (this.isPlan30k_10k) {
        return {
          fontSize: '3.2vw',
        };
      } else if (this.isPlanKid2week) {
        return {
          fontSize: '5.625vw',
        };
      } else {
        return {
          fontSize: '5.625vw',
        };
      }
    },
  },
  methods: {
    getDayText(day) {
      return String(day).split('.').join('-');
    },
    go2lesson(num) {
      this.$emit('go2lesson', num);
    },
  },
};
</script>

<style lang="scss" scoped>
.process-text {
  color: $font-normal;
}
.process-content {
  display: grid;
  grid-column-gap: 4px;
  grid-row-gap: 16px;
  justify-items: center;
  align-items: center;
}
.course-status {
  border-radius: 10px;
  box-shadow: 0 2px 7px 0 rgba(199, 199, 199, 0.3);
}
.lesson-day {
  background-color: $disable;
  border-radius: 50%;
  span {
    font-weight: bold;
    font-size: 3.733vw;
  }
  > i {
    font-size: 3.733vw;
  }
  &.lesson-finish {
    background-color: $secondary;
  }
}
.finish-record {
  bottom: 0;
  background-color: $primary;
  border-radius: 3px;
  i {
    color: $grayscale-20;
    &.bright {
      color: white;
    }
  }
}
</style>

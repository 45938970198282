const mixinObject = {
  data() {
    return {};
  },
  computed: {
    isPlan30k_10k() {
      return ['30k-10k', '30k-10k-v2'].includes(
        this.$store.getters['course/currentPlan'].id
      );
    },
    isPlanKid1week() {
      return this.$store.getters['course/isCurrentPlan']('kid1week');
    },
    isPlanKid2week() {
      return this.$store.getters['course/isCurrentPlan']('kid2week');
    },
    isPlan10k_8k() {
      return this.$store.getters['course/isCurrentPlan']('10k-8k');
    },
    isPlan1d_2d() {
      return this.$store.getters['course/isCurrentPlan']('trial-01');
    },
    isPlanKidtrial_02() {
      return this.$store.getters['course/isCurrentPlan']('kidtrial-02');
    },
    isPlanTrial_01() {
      return this.$store.getters['course/isCurrentPlan']('trial-01');
    },
  },
};

export default mixinObject;

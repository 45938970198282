<template>
  <div class="wrap-course-status position-relative pt-3 pb-6">
    <div class="wrapper bg-white rounded-10 px-6 py-4">
      <b-list-group v-if="processCourses.length !== 0">
        <b-list-group-item
          v-for="(course, index) in processCourses"
          :key="index"
        >
          <div class="d-flex justify-content-between align-items-center w-100">
            <div class="d-flex">
              <div class="mr-2">
                {{ getCourseTitle(course.day, course.title) }}
              </div>
              <i
                v-if="course.isLocked"
                class="icon-Lock text-24 ml-2"
                style="color: #c0c4cb"
              ></i>
            </div>
            <div v-if="!course.isLocked" class="d-flex align-items-center">
              <img
                v-if="course.totalFinish === course.totalCourses"
                :srcset="require('@/assets/img/common/completed.png?srcset')"
                width="99"
                class="mr-2"
              />
              <div
                class="star-wrapper mr-3 d-flex justify-content-center align-items-center px-3"
              >
                <i
                  v-for="i in course.totalCourses"
                  :key="i"
                  class="icon-Star"
                  :class="[{'text-primary': course.totalFinish >= i}]"
                ></i>
              </div>
              <div
                class="link text-white mr-2 cursor-pointer d-flex justify-content-center align-items-center"
                @click="go2lesson(course)"
              >
                <i class="icon-Chevron-right"></i>
              </div>
            </div>
          </div>
        </b-list-group-item>
      </b-list-group>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    processCourses: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    userData() {
      return this.$store.getters['user/userData'];
    },
  },
  methods: {
    getCourseTitle(day, title) {
      const newDayText = String(day).split('.').join('-');
      return `Day${newDayText} ${title}`;
    },
    go2lesson(num) {
      this.$emit('go2lesson', num);
    },
  },
};
</script>

<style lang="scss" scoped>
.wrap-course-status {
  overflow: overlay;
  max-width: 100%;
  margin: 0 auto;
  padding: 0 32px;
}
.wrapper {
  box-shadow: 0px 2px 7px rgba(199, 199, 199, 0.3);
}
.link {
  width: 38px;
  height: 38px;
  margin-bottom: 3px;
  line-height: 20px;
  border-radius: 50%;
  background: $primary;
  font-size: 28px;
  @media screen and (min-width: 768px) {
    margin-bottom: 0px;
  }
}
.star-wrapper {
  width: 154px;
  background: #ebeef5;
  border-radius: 100px;
  @media screen and (min-width: 768px) {
    height: 38px;
  }
  i {
    margin-right: 6px;
    font-size: 36px;
    color: $grayscale-20;
    display: flex;
    align-items: center;
    justify-content: center;
    &:nth-last-child(3n + 1) {
      margin: 0;
    }
  }
}
.list-group-item {
  display: flex;
  align-items: center;
  width: 100%;
  height: 60px;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  &:hover {
    background: rgba(0, 0, 0, 0.02);
  }
}

@media screen and (min-width: 1280px) {
  .wrap-course-status {
    max-width: 1172px;
  }
}
</style>

<template>
  <div
    class="course-status d-flex flex-column h-100 position-relative"
    :class="{'overflow-hidden': $device.isMobile}"
  >
    <base-header
      :title="$t('學習進度')"
      :left-arrow="true"
      right-icon="Arrow-left-right"
      :breadcrumb="breadcrumb"
      :mode="$device.isMobile ? 'secondary' : 'primary'"
      @on-click-right="$store.commit('env/setIsSwitchCourseSheetShow', true)"
    ></base-header>

    <loading-wrapper v-if="isPageLoading"></loading-wrapper>

    <div v-else class="wrapper scroll-bar">
      <div class="course-status-container">
        <div v-if="$device.isMobile">
          <MobileCourseStatus
            :process-courses="processCourses"
            @go2lesson="go2lesson"
          />
        </div>
        <div v-else class="pt-6">
          <PlansDropdown position="unset" />
          <WebCourseStatus
            :process-courses="processCourses"
            @go2lesson="go2lesson"
          ></WebCourseStatus>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseHeader from '@/components/Base/BaseHeader';
import LoadingWrapper from '@/components/Base/LoadingWrapper.vue';
import PlansDropdown from '@/components/Base/PlansDropdown.vue';
import WebCourseStatus from '@/components/Personal/WebCourseStatus.vue';
import MobileCourseStatus from '@/components/Personal/MobileCourseStatus.vue';

export default {
  components: {
    BaseHeader,
    LoadingWrapper,
    PlansDropdown,
    WebCourseStatus,
    MobileCourseStatus,
  },
  data() {
    return {
      isPageLoading: false,
      isSwitchCourseSheetShow: false,
      rules: [
        this.$t(
          '計算方式：以每月 30 天，不分大小月的方式來計算。如有購買多堂課程或續購直播講堂方案，時數將自動累加延長。'
        ),
        this.$t(
          'LIVE結束：每個直播講堂結束後將保留 90 天。若錯過喜歡的講堂，可以在直播列表的篩選器中，選擇時間的【已結束（可播放的課程）】分類，找到該講堂點擊【播放】觀看喔！'
        ),
        this.$t('到期：直播講堂到期後，將無法觀看任何內容。'),
      ],
      breadcrumb: [
        {
          name: this.$t('個人'),
          enable: true,
          router: 'personal',
        },
        {
          name: this.$t('學習進度'),
          enable: true,
          router: 'course-status',
        },
      ],
    };
  },
  computed: {
    userData() {
      return this.$store.getters['user/userData'];
    },
    currentPlan() {
      return this.$store.getters['course/currentPlan'];
    },
    currentCourses() {
      return this.$store.getters['course/currentCourses'];
    },
    isCN() {
      return this.$store.getters['env/isCN'];
    },
    processCourses() {
      return this.currentCourses
        .map((course) => {
          if (course.day !== 0) {
            let data = {};
            data = {
              day: course.day,
              totalFinish: course.content.filter(
                ({status}) => status === 'COMPLETED'
              ).length,
              totalCourses: course.content.length,
              isLocked: course?.isLock,
              title: course?.content[0].title,
            };
            return data;
          }
        })
        .filter((data) => data);
    },
  },
  created() {
    this.getCourse();
  },
  methods: {
    async getCourse() {
      try {
        this.isPageLoading = true;
        await this.$store.dispatch('course/getCourses', this.currentPlan.id);
      } finally {
        this.isPageLoading = false;
      }
    },
    go2lesson(course) {
      if (!course.isLocked) {
        this.$store.commit('course/setAnchorDay', course.day);
        this.$router.push('home');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.course-status {
  .wrapper {
    overflow: overlay;
  }
  .expired-time-card {
    background: white;
    .text {
      font-weight: bold;
      font-size: 18px;
      line-height: 30px;
    }
  }
  .record-card {
    line-height: 28px;
    color: $font-grayscale-1;
    .title {
      padding: 11px 16px;
      border-bottom: 1px solid $line-2;
    }
  }
  .rule-card {
    .title {
      padding: 5px 16px;
      line-height: 30px;
    }
    ol {
      padding: 0px 8px 0px 32px;
    }
  }
}

@media screen and (min-width: 768px) {
  .course-status {
    .wrapper {
      height: calc(100vh - 80px);
    }
    .live-course-records-container {
      max-width: 720px;
      margin: 40px auto;
      padding: 0 16px;
    }
    .expired-time-card,
    .rule-card,
    .record-card {
      border-radius: 10px;
      box-shadow: 0px 2px 7px rgba(199, 199, 199, 0.3);
    }
    .expired-time-card {
      .text {
        width: 100%;
      }

      .title {
        width: 100%;
        display: block;
        color: $primary;
        padding: 5px 16px;
        border-bottom: none;
        background: $bgsection;
        border-radius: 10px;
      }
      .time {
        display: block;
        padding: 10px 16px;
      }
    }
    .record-card {
      .title {
        color: $primary;
        padding: 5px 16px;
        border-bottom: none;
        background: $bgsection;
        border-radius: 10px;
        line-height: 30px;
      }
    }

    .rule-card {
      ol {
        font-size: 18px;
        line-height: 30px;
        padding: 0px 0px 0px 24px;
      }
    }
  }
}

@media screen and (min-width: 1200px) {
  .course-status {
    .live-course-records-container {
      max-width: 1140px;
    }
  }
}
</style>
